.login-container {
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    margin: 0 auto;

    background-image: url('../assets/imageCenterLogin.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 45% 70%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0e2df;
}

.login-container section.form {
    width: 100%;
    max-width: 550px;
    margin-right: 30px;
}

.login-container section.form form {
    margin-top: 0px;
    padding-left: 100px;
    padding-right: 100px;
}

.login-container section.form form h1 {
    font-size: 32px;
    margin-bottom: 32px;
}

.login-container section.form form input {
    margin-top: 8px;
}

.login-container section.form form p {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
    text-align: center;
}

.title {
    font-size: 32px;
    text-align: center;
    color: #591024;
    font-family: 'aller_displayregular', sans-serif;
    text-transform: uppercase;
}

.divForm {
    text-align: center;
}

.divForm h3 {
    font-size: 22px;
    color: #ce908c;
    line-height: 32px;
    text-align: center;
}

.divForm p {
    font-size: 18px;
    font-weight: 600;
    color: #41414d;
    line-height: 32px;
    text-align: center;
}

.divForm span {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
    text-align: center;
}
