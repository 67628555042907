/*Cores do sistema
fundo: #f0e2df
verde: #dfebba
rosa fraco: #fbe2ec
rosa forte: #BC4E6B
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@font-face {
    font-family: 'aller_displayregular';
    src: url('../assets/allerdisplay-webfont.woff2') format('woff2'),
        url('../assets/allerdisplay-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px Roboto, sans-serif;
    background: #f0e2df;
    -webkit-font-smoothing: antialiased;
}

input,
button,
textarea,
select,
option {
    font: 400 18px Roboto, sans-serif;
}

button,
select,
option {
    cursor: pointer;
}

form input,
select,
option {
    /*width: 100%;*/
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
}

.button {
    width: 100%;
    height: 60px;
    background: #bc4e6b;
    border: 0;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 60px;
    transition: filter 0.2s;
}

.button:hover {
    filter: brightness(90%);
}

.back-link {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #41414d;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.request-link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #737380;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.back-link svg {
    margin-right: 8px;
}

.back-link:hover {
    opacity: 0.8;
}

.request-link svg {
    margin-right: 8px;
}

.request-link:hover {
    opacity: 0.8;
}

.sm-icon {
    size: 16;
    color: #bc4e6b;
}

.errorMessage {
    color: red;
    font: 500 16px Roboto, sans-serif;
    padding: 0 24px;
    display: none;
}

.message {
    margin-top: 8px;
    background: #fff;
    padding: 20px;
    border: 2px solid #dcdce6;
    border-radius: 8px;
}

.message ul {
    margin-left: 42px;
    color: #41414d;
    font: 500 15px Roboto, sans-serif;
}

.message h3 {
    font: 500 18px Roboto, sans-serif;
    color: #41414d;
}

.message-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.message-title svg {
    margin-right: 8px;
}

.message-sm-icon {
    size: 16;
    color: #591024;
}

table {
    border: 1px solid #757581;
    border-radius: 8px;
    width: 100%;
    background: #ffffff;
    border-collapse: collapse;
}

thead {
    width: 100%;
    text-align: center;
    font: 400 18px Roboto, sans-serif;
}

tr {
    line-height: 45px;
}

th {
    padding: 10px;
    border-bottom: 1px solid #dcdce6;
    border-collapse: collapse;
}

td {
    padding: 10px;
    border-bottom: 1px solid #dcdce6;
    border-collapse: collapse;
    text-align: center;
    font: 400 18px Roboto, sans-serif;
}
