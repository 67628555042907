* {
    margin: 0;
    padding: 0;
    outline: 0;
}

.perfil-container {
    width: 100%;
    max-width: 600px;
    /* height: 100vh; */
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
}

.content {
    padding: 0;
}

.perfil-container .content {
    width: 100%;
    max-width: 600px;
}

.perfil-container .content form input {
    margin-top: 8px;
}

.perfil-container .content form p {
    font-size: 18px;
    color: #737380;
    line-height: 32px;
    padding: 0 24px;
    left: 50%;
}

.perfil-container .content h1 {
    line-height: 32px;
    padding: 0 24px;
    left: 50%;
}

.perfil-container .content form .input-group {
    display: flex;
}

.perfil-container .content form .input-group input + input {
    margin-left: 8px;
}

.perfil-container .content h3 {
    color: #591024;
}

.perfil {
    display: flex;
    justify-content: center;
}

.btnSec {
    width: 45%;
}

.perfil-container form button + button {
    margin-left: 8px;
}

#ufHome {
    width: 20%;
}

#paisHome {
    width: 29%;
}

.button {
    background: #591024;
}

#title {
    color: #591024;
    text-align: center;
}
